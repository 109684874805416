const experience = [
  {
    name: 'IG',
    date: '2019-2023',
    position: 'Web Developer',
    desc: 'Responsible for developing:',
    descList: [
      'a static website, generated by Middleman (Ruby framework) hosted in S3 (AWS)',
      'backend application written in PHP, which handled the website forms and was integrated with third party applications',
      'a website (Drupal CMS hosted in Acquia) - adding new functionality, maintenance, etc.',
    ],
    stack: ['JavaScript', 'TypeScript', 'PHP', 'Ruby', 'API', 'HTML', 'CSS'],
  },
  {
    name: 'Web House',
    date: '2018-2019',
    position: 'Web Developer',
    desc: 'Self-employment:',
    descList: [
      'outsourcing in the following area: PHP, SQL, HTML, JS, CSS',
      'creating dedicated Wordpress themes for small firms',
    ],
    stack: ['JavaScript', 'PHP', 'MySQL', 'Wordpress', 'HTML', 'CSS'],
  },
  {
    name: 'Akademia Biznesu',
    date: '2016-2018',
    position: 'Frontend Developer',
    desc: 'Responsible for:',
    descList: [
      'e-learning platform development',
      'creating landing pages',
      'maintaining Wordpress websites',
    ],
    stack: ['JavaScript', 'HTML', 'CSS', 'Wordpress', 'jQuery'],
  },
  {
    name: 'Best Group',
    date: '2015-2017',
    position: 'Full-Stack Developer',
    desc: 'E-commerce platform development.',
    descList: [],
    stack: ['JavaScript', 'PHP', 'MySQL', 'HTML', 'CSS', 'jQuery'],
  },
];

export default experience;
