import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import RootLayout from './layouts/Root';
import HomePage from './pages/Home';
import ResumePage from './pages/Resume';
import PortfolioPage from './pages/Portfolio';
import ContactPage from './pages/Contact';
import ErrorPage from './pages/Error';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'resume', element: <ResumePage /> },
      { path: 'portfolio', element: <PortfolioPage /> },
      { path: 'contact', element: <ContactPage /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
