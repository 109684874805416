const portfolio = [
  {
    name: '<zboro.dev />',
    id: 'zboro',
    date: '2023',
    image: '/images/01.jpg',
    linkName: 'zboro.dev',
    url: 'https://zboro.dev',
    descList: ['Portfolio website', 'Single Page Application'],
    stack: ['React', 'React Router', 'Tailwind CSS', 'Design'],
  },
  {
    name: 'Todo Advanced',
    id: 'todo',
    date: '2023',
    image: '/images/02.jpg',
    linkName: 'todo.zboro.dev',
    url: 'https://todo.zboro.dev',
    descList: [
      'Organize time by prioritizing tasks',
      'Display tasks for tomorrow and the day after',
      'Use dark or light mode',
    ],
    stack: [
      'React',
      'Redux',
      'Redux Toolkit',
      'CSS Module',
      'LocalStorage',
      'Design',
    ],
  },
  {
    name: 'Dzieło Pomocy św. Ojca Pio',
    id: 'dzielo',
    date: '2022',
    image: '/images/03.jpg',
    linkName: 'dzielopomocy.pl',
    url: 'https://dzielopomocy.pl',
    descList: [
      'Website created for a charity organization from Krakow supporting poor and homeless people',
    ],
    stack: ['Wordpress', 'Template creating', 'Payments'],
  },
  {
    name: 'Święty Józef - Android App',
    id: 'jozef',
    date: '2021',
    image: '/images/04.jpg',
    linkName: 'Google Play',
    url: 'https://play.google.com/store/apps/details?id=pl.net.kaleb.jozef&hl=pl&gl=US',
    descList: ['Mobile application created using web technologies'],
    stack: ['JavaScript', 'Cordova', 'Android Studio', 'Design'],
  },
  {
    name: 'Gościna Kraków',
    id: 'goscina',
    date: '2018',
    image: '/images/05.jpg',
    linkName: 'goscina.krakow.pl',
    url: 'https://www.goscina.krakow.pl',
    descList: ['Holiday guesthouse website'],
    stack: ['Wordpress', 'Template creating', 'SEO optimization', 'Design'],
  },
  {
    name: 'Kapucyni',
    id: 'kapucyni',
    date: '2018',
    image: '/images/06.jpg',
    linkName: 'kapucyni.pl',
    url: 'https://kapucyni.pl',
    descList: ['Website of the Order of Friars Minor Capuchin from Poland'],
    stack: ['Wordpress', 'Bootstrap', 'Template creating'],
  },
  {
    name: 'Ludens Agro - Web & iOS App',
    id: 'ludens',
    date: '2018',
    image: '/images/07.jpg',
    linkName: '',
    url: '',
    descList: [
      'Engineering project: „Design and implementation of an outdoor game system using JavaScript and MongoDB technologies”',
      'Dual system: web application (game creation module) and iOS mobile application (game playing module)',
      'Prototype version - not available online',
    ],
    stack: [
      'Angular 2+',
      'Angular Material',
      'Ionic',
      'Node.js',
      'MongoDB',
      'Design',
    ],
  },
  // {
  //   name: 'Szansa Spotkania',
  //   id: 'szansa',
  //   date: '2018',
  //   image: '/images/08.jpg',
  //   linkName: 'szansaspotkania.pl',
  //   url: 'https://szansaspotkania.pl',
  //   descList: ['Marriage support counseling website.'],
  //   stack: ['Wordpress', 'Template creating', 'Payments', 'Design'],
  // },
];

export default portfolio;
