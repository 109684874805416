import { Link } from "react-router-dom";

const RecentProjectsItem = (props) => {
  return (
    <Link to={props.link} className='w-[48%] sm:w-[22%] mb-4 sm:mb-0'>
      <img
        className='rounded-2xl border-2 border-gray-600 grayscale hover:grayscale-0 transition duration-300 ease-in-out'
        src={props.image}
        alt={props.alt}
      />
    </Link>
  );
};

export default RecentProjectsItem;
