import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SectionTitle from '../components/SectionTitle';
import PortfolioItem from '../components/portfolio/PortfolioItem';
import portfolio from '../data/portfolio';
import Section from '../ui/Section';

const PortfolioPage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }, 300);
    }
  }, [hash]);

  return (
    <>
      <SectionTitle title='Portfolio' />
      <Section title='Some Projects'>
        {portfolio.map((item) => (
          <PortfolioItem
            key={item.id}
            id={item.id}
            name={item.name}
            date={item.date}
            image={item.image}
            linkName={item.linkName}
            url={item.url}
            descList={item.descList}
            stack={item.stack}
          />
        ))}
      </Section>
    </>
  );
};

export default PortfolioPage;
