import Hero from '../components/home/Hero';
import SectionSkills from '../components/home/SectionSkills';
import SectionRecentProjects from '../components/home/SectionRecentProjects';

const HomePage = () => {
  return (
    <>
      <Hero />
      <SectionSkills />
      <SectionRecentProjects />
    </>
  );
};

export default HomePage;
