import Section from '../../ui/Section';
import education from '../../data/education';
import ResumeItem from './ResumeItem';

const SectionEducation = () => {
  return (
    <Section title='Education'>
      {education.map((item) => (
        <ResumeItem
          key={item.name}
          date={item.date}
          name={item.name}
          position={item.position}
          desc={item.desc}
          descList={item.descList}
          stack={item.stack}
          smallView={item.smallView}
        />
      ))}
    </Section>
  );
};

export default SectionEducation;
