const Rate = (props) => {
  const { rate, name } = props;

  const labels = ['Basic', 'Junior', 'Regular', 'Advanced', 'Expert'];

  const rateRings = (
    <div className='flex'>
      {labels.map((item, index) => {
        const ringColor = rate >= index + 1 ? 'bg-accent3' : 'bg-gray-300';
        return (
          <div
            key={index}
            className={`w-3 h-3 mr-3 rounded-full ${ringColor}`}
          ></div>
        );
      })}
    </div>
  );

  return (
    <div className='flex flex-col w-36'>
      {rateRings}
      <h4 className='text-xl mt-2 mb-1'>{name}</h4>
      <p className='text-gray-500'>{labels[rate - 1]}</p>
    </div>
  );
};

export default Rate;
