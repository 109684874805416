const skills = [
  { name: 'JavaScript', rate: '3' },
  { name: 'React/Redux', rate: '3' },
  { name: 'PHP', rate: '3' },
  { name: 'MySQL', rate: '3' },
  { name: 'HTML', rate: '4' },
  { name: 'CSS (SCSS)', rate: '4' },
  { name: 'Linux', rate: '3' },
  { name: 'Apache', rate: '3' },
  { name: 'Wordpress', rate: '4' },
  { name: 'REST API', rate: '3' },
  { name: 'Git', rate: '3' },
  { name: 'AWS (S3, CF)', rate: '3' },
];

export default skills;
