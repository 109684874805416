import Section from '../../ui/Section';
import RecentProjectsItem from './RecentProjectsItem';

import projectZboroImg from '../../assets/images/portfolio/project_zboro.jpg';
import projectTodoImg from '../../assets/images/portfolio/project_todo.jpg';
import projectDzieloImg from '../../assets/images/portfolio/project_dzielo.jpg';
import projectJozefImg from '../../assets/images/portfolio/project_jozef.jpg';

const SectionRecentProjects = () => {
  return (
    <Section title='Recent Projects'>
      <div className='flex flex-wrap justify-between'>
        <RecentProjectsItem
          link='portfolio#zboro'
          image={projectZboroImg}
          alt='Project zboro.dev'
        />
        <RecentProjectsItem
          link='portfolio#todo'
          image={projectTodoImg}
          alt='Project TodoAdvanced'
        />
        <RecentProjectsItem
          link='portfolio#dzielo'
          image={projectDzieloImg}
          alt='Project Dzieło Pomocy'
        />
        <RecentProjectsItem
          link='portfolio#jozef'
          image={projectJozefImg}
          alt='Project Święty Józef'
        />
      </div>
    </Section>
  );
};

export default SectionRecentProjects;
