import { NavLink } from 'react-router-dom';

const routes = [
  { url: '', name: 'About me' },
  { url: 'resume', name: 'Resume' },
  { url: 'portfolio', name: 'Portfolio' },
  { url: 'contact', name: 'Contact' },
];

const Navigation = () => {
  return (
    <nav className='md:ml-auto'>
      <ul className='flex gap-4 sm:gap-6'>
        {routes.map((route) => (
          <li key={route.name}>
            <NavLink
              to={route.url}
              className={({ isActive }) =>
                isActive ? 'text-accent3' : 'hover:text-accent3'
              }
              end
            >
              {route.name}
            </NavLink>
          </li>
        ))}
        <li className='hidden sm:list-item'>
          <a
            className='bg-accent1 p-1 rounded-sm'
            href='https://www.linkedin.com/in/marek-zborowski-294890185'
            target='_blank'
            rel='noreferrer'
          >
            in
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
