const Section = (props) => {
  const afterClasses =
    'after:absolute after:-bottom-0.5 after:left-0 after:w-8 after:border-b-2 after:border-accent3';

  return (
    <div className='flex flex-col mb-16'>
      <h1 className={`text-2xl border-b-2 w-fit mb-8 relative ${afterClasses}`}>
        {props.title}
      </h1>
      {props.children}
    </div>
  );
};

export default Section;
