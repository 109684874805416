import Footer from './Footer';
import Header from './Header';

const Container = (props) => {
  return (
    <div className='container mx-auto max-w-7xl sm:py-8 lg:py-12 sm:px-8 min-h-screen flex'>
      <div className='columns-1 flex-1 flex flex-col sm:rounded-3xl bg-gray-900 text-gray-50 sm:shadow-2xl font-mono overflow-hidden'>
        <Header />
        <main className='pt-6 px-6 sm:pt-10 sm:px-10'>{props.children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Container;
