const ResumeItem = (props) => {
  const { name, date, position, desc, descList, stack, smallView } = props;
  
  return (
    <div className='flex flex-col sm:flex-row sm:gap-10 mb-8'>
      <div className='flex sm:flex-col pb-2 border-gray-600 text-right sm:w-32'>
        <h5>{date}</h5>
        <p
          className={`ml-4 sm:ml-0 text-accent3 ${
            smallView ? 'sm:text-sm' : 'sm:text-lg'
          }`}
        >
          {name}
        </p>
      </div>
      <div className='flex flex-col pb-4 border-b-2 border-gray-600 sm:flex-1'>
        <h5 className='text-xl mb-2'>{position}</h5>
        <p className='text-sm'>{desc}</p>
        <ul className='text-sm list-disc ml-8'>
          {descList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className='flex flex-wrap mt-4 text-sm text-gray-400'>
          {stack.map((item) => (
            <span
              key={item}
              className='mx-1 mb-2 py-1 px-3 border rounded-lg border-gray-600'
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResumeItem;
