import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';

const Header = () => {
  return (
    <header className='my-10 mx-10 flex items-center flex-col md:flex-row '>
      <Link to='' className='text-3xl mb-5 md:mb-0'>
        <span className='text-accent3'>{`<`}</span>
        zboro.dev
        <span className='text-accent3'>{`/>`}</span>
      </Link>
      <Navigation />
    </header>
  );
};

export default Header;
