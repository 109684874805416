const education = [
  {
    name: 'Wyższa Szkoła Zarządzania i Bankowości',
    date: '2013-2018',
    position: 'IT Engineer',
    desc: 'Specialization: Web Applications',
    descList: [],
    stack: [],
    smallView: true,
  },
];

export default education;
