import SectionTitle from '../components/SectionTitle';

const ContactPage = () => {
  return (
    <>
      <SectionTitle title='Contact' />
      <p className='text-center'>marek7654[at]gmail.com</p>
    </>
  );
};

export default ContactPage;
