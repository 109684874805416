const Footer = () => {
  return (
    <footer className='mt-auto bg-gray-800 p-6 text-center border-t-2 border-gray-600 text-gray-300'>
      <p>
        <span className='font-sans'>©</span> 2023 - {`<zboro.dev/>`}
      </p>
    </footer>
  );
};

export default Footer;
