import { useRouteError } from 'react-router-dom';

import Container from '../layouts/Container';

const ErrorPage = () => {
  const error = useRouteError();

  let title = 'An error occurred!';
  let message = 'Something went wrong :-(';

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = '404';
    message = 'Page not found';
  }

  return (
    <Container>
      <h1 className='text-center text-5xl mb-5'>{title}</h1>
      <p className='text-center text-2xl'>{message}</p>
    </Container>
  );
};

export default ErrorPage;
