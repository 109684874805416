import { Link } from 'react-router-dom';

const Button = (props) => {
  const { name, link, accent, classes } = props;

  const accentClasses = accent
    ? ['border-accent3', 'hover:bg-accent3']
    : ['hover:text-gray-800', 'hover:bg-gray-100'];

  let finalClasses = ['py-1', 'px-5', 'border-2', 'rounded-2xl']
    .concat(accentClasses)
    .concat(classes)
    .join(' ');

  return (
    <Link className={finalClasses} to={link}>
      {name}
    </Link>
  );
};

export default Button;
