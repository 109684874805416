import SectionTitle from '../components/SectionTitle';
import SectionEducation from '../components/resume/SectionEducation';
import SectionExperience from '../components/resume/SectionExperience';

const ResumePage = () => {
  return (
    <>
      <SectionTitle title='Resume' />
      <SectionExperience />
      <SectionEducation />
    </>
  );
};

export default ResumePage;
