import Button from '../../ui/Button';

import marekPhoto from '../../assets/images/marek4.jpg';

const Hero = () => {
  return (
    <div className='flex flex-col md:flex-row items-center mb-12'>
      <div className='flex-1'>
        <img
          className='w-72 lg:w-80 mx-auto mb-8 md:mr-16 md:float-right rounded-full border-8 border-gray-600'
          src={marekPhoto}
          alt='Marek Zborowski'
        />
      </div>
      <div className='flex-1 flex flex-col text-center md:text-left'>
        <h3 className='mb-2 text-xl text-gray-400'>Web Developer</h3>
        <h1 className='mb-6 text-4xl'>Marek Zborowski</h1>
        <p className='mb-8'>
          I have over 8 years of web development experience. I&nbsp;have worked for
          a&nbsp;few companies and completed several smaller projects.
          My&nbsp;strength as a&nbsp;frontend developer is extensive experience
          in&nbsp;backend programming and working with databases. I&nbsp;like cycling.
        </p>
        <div className='flex justify-center md:justify-start'>
          <Button name='Portfolio' link='portfolio' accent={true} />
          <Button name='Contact' link='contact' classes={['ml-6']} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
