const PortfolioItem = (props) => {
  const { name, id, date, image, linkName, url, descList, stack } = props;

  return (
    <div
      id={id}
      className='flex flex-col sm:flex-row sm:items-center xl:items-start sm:gap-10 mb-12'
    >
      <div
        className='portfolio-img-wrapper relative overflow-hidden flex-1'
        data-date={date}
      >
        <img
          className='rounded-2xl border-2 border-gray-600'
          src={image}
          alt={name}
        />
      </div>
      <div className='flex flex-col py-6 flex-1 lg:flex-2'>
        <h3 className='text-2xl mb-2'>{name}</h3>
        <ul className='text-sm list-disc ml-8 mb-8'>
          {descList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          <a
            className='text-accent3'
            href={url}
            target='_blank'
            rel='noreferrer'
          >
            {linkName}
          </a>
        </p>
        <div className='flex flex-wrap mt-4 text-sm text-gray-400'>
          {stack.map((item) => (
            <span
              key={item}
              className='mx-1 mb-2 py-1 px-3 border rounded-lg border-gray-600'
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioItem;
