import Section from '../../ui/Section';
import experience from '../../data/experience';
import ResumeItem from './ResumeItem';

const SectionExperience = () => {
  return (
    <Section title='Experience'>
      {experience.map((item) => (
        <ResumeItem
          key={item.name}
          date={item.date}
          name={item.name}
          position={item.position}
          desc={item.desc}
          descList={item.descList}
          stack={item.stack}
          smallView={item.smallView}
        />
      ))}
    </Section>
  );
};

export default SectionExperience;
