import Section from '../../ui/Section';
import Rate from './Rate';
import skills from '../../data/skills';

const SectionSkills = () => {
  return (
    <Section title='Skills'>
      <div className='flex flex-wrap gap-6 lg:gap-10'>
        {skills.map((item) => (
          <Rate key={item.name} name={item.name} rate={item.rate} />
        ))}
      </div>
    </Section>
  );
};

export default SectionSkills;
